.address {
    font-size: 18px;
    margin-left: 7%;
  }
.email {
    font-size: 18px;
    margin-left: 7%;
    }
.phone {
    font-size: 18px;
    margin-left: 7%;
    }
.CompanyNb {
    font-size: 18px;
    margin-left: 7%;
    }
.Conf {
    font-size: 18x;
    margin-left: 15%;
    }
h1 {
    font-size: 35px;
    margin: 0px 0px 0 7%;
    }
footer {
  width: 100%;
  word-break: break-all;
  bottom: 0;
  background-color: white;

}
.CompanyInfo {
    padding: 0 0;
    list-style: none;
}

.CompanyInfo li{
    display: inline;
}

@media only screen and (max-width: 600px) {
    .Conf {
        margin-left: 7%;
        }
    .CompanyInfo li{
        display: block;
    }
    footer {
        text-align: center;
        width: 100%;
        word-break: break-all;
        bottom: 0;
        position: relative;
        margin-top: 10%;
      }
  }